<!-- Created by henian.xu on 2021/10/27. -->

<template>
  <Page>
    <container ref="container">
      <XForm
        ref="form"
        :model="formModel"
        :rules="formRules"
      >
        <div class="form-box-wrap">
          <div class="inner">
            <FormItem prop="feedbackType">
              <Selector
                :data="feedbackType"
                placeholder="请选择反馈类型"
                v-model="formModel.feedbackType"
              />
            </FormItem>
            <FormItem
              prop="content"
              class="column first-child"
            >
              <XInput
                class="content-input"
                type="textarea"
                :auto-size="{minRows:4,maxRows:6}"
                v-model="formModel.content"
              />
            </FormItem>
            <FormItem
              prop="feedbackImage"
              class="first-child"
            >
              <upload
                action="/rpc/comm/rs/attachment/uploadFileLibrary"
                accept="image/*"
                tips="上传图片"
                v-model="formModel.feedbackImage"
                :column="4"
              />
            </FormItem>
          </div>
        </div>
      </XForm>
      <Widget
        :id="15"
        no-show-complete
      />
    </container>
    <PageBar class="footer padding">
      <Button
        class="item"
        theme="main"
        radius="all"
        @click="()=>{onSubmit()}"
      >
        提交
      </Button>
    </PageBar>
  </Page>
</template>

<script>
export default {
  name: 'AddPage',
  data() {
    return {
      feedbackType: [],
      formModel: {
        feedbackType: '', // 工单类型
        content: '', //正文
        feedbackImage: [], // 工单图片
      },
      formRules: {
        feedbackType: {
          required: true,
          message: '工单类型不能为空',
        },
      },
    };
  },
  computed: {
    isReplace() {
      return this.$route.query.hasOwnProperty('replace');
    },
  },
  methods: {
    async getFeedbackType() {
      const { data: res } = await this.$api.Comm.Rs.Dic.dicEntry(
        'feedbackType',
      );
      console.log(res.data);
      this.feedbackType = res.data;
    },
    async onSubmit() {
      await this.$refs['form'].validate();
      const { formModel } = this;
      const params = {
        ...formModel,
        feedbackImage: formModel.feedbackImage.map(n => n.name).join(','),
      };
      console.log('onSubmit', params);

      const {
        data: { msg },
      } = await this.$api.Feedback.Mb.Feedback.submitFeedback(params);
      this.$messageBox.tips(msg);
      if (this.isReplace) {
        // this.$router.push('/feedback/list');
        window.location.href = this.$globalVar.feedbackUrl;
      } else {
        this.$router.go(-1);
      }
    },
  },
  created() {
    this.getFeedbackType();
  },
};
</script>

<style lang='scss' scoped>
.content-input {
  background-color: $gray2 !important;
  border-radius: $padding-small;
}
</style>
