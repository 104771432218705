<!-- Created by henian.xu on 2017/10/22. -->
<template>
  <Page>
    <container ref="container">
      <list>
        <Linker
          class="feedback-item"
          v-for="item in listData"
          :key="item.id"
          :to="`/feedback/detail/${item.id}`"
        >
          <div class="header">
            <div class="label">
              {{ item.feedbackTypeName }}
            </div>
            <div class="extra">
              {{ item.feedbackTime | moment('YYYY-MM-DD HH:mm:ss') }}
            </div>
          </div>
          <div class="body">
            {{ item.content }}
          </div>
        </Linker>
      </list>
      <infinite-loading
        ref="infinite"
        :disabled="infiniteDisabled"
        @infinite="onInfinite"
      />
    </container>
    <PageBar>
      <Button
        theme="main"
        to="/feedback/add"
      >
        新增
      </Button>
    </PageBar>
  </Page>
</template>

<script>
import pageMixin from '@/mixins/layout/page';
import infiniteLoading from '@/mixins/infiniteLoading';

export default {
  name: 'RefundList',
  mixins: [pageMixin, infiniteLoading],
  data() {
    return {
      listData: [],
    };
  },
  computed: {},
  methods: {
    getListData({ currentPage, pageSize } = {}) {
      return this.$api.Feedback.Mb.Feedback.feedbackList({
        currentPage:
          currentPage || this.pagination.currentPage
            ? this.pagination.currentPage + 1
            : 1,
        pageSize,
      }).then(json => {
        const res = json.data;
        this.listData = this.listData.concat(res.data);
        return Promise.resolve(json);
      });
    },
  },
};
</script>

<style lang="scss">
.feedback-item {
  background-color: #fff;
  padding: $padding;
  display: block;

  > .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    > .label {
      flex: 1 1 1%;
      font-weight: bold;
    }
    > .extra {
      flex: 0 0 auto;
      color: $color-text-minor;
    }
  }

  > .body {
    color: $color-text-minor;
  }

  + .feedback-item {
    margin-top: $margin;
  }
}
</style>
