<!-- Created by henian.xu on 2017/10/22. -->
<template>
  <Page>
    <container ref="container">
      <div class="goods-swiper">
        <ad-swiper
          is-images-background
          :data="imgList"
        />
        <!--<div class="sold-out"
                     v-if="goods.sellQuantity>=goods.limitQuantity"
                ></div>-->
      </div>
      <div class="goods-info">
        <div class="header">
          <div class="price-wrap">
            <div class="price">
              {{ goods.price }}
            </div>
            <div
              v-if="goods.marketPrice"
              class="old-price"
            >
              销售价 <span class="price">{{ goods.marketPrice }}</span>
            </div>
          </div>
          <div class="countdown-wrap">
            <countdown
              v-model="countdown"
              :start-timestamps="goods.startTimestamps"
              :end-timestamps="goods.endTimestamps"
            />
          </div>
        </div>
        <div class="body">
          <div class="label-wrap">
            <div class="name">
              {{ goods.name }}
            </div>
            <div class="desc">
              {{ goods.desc }}
            </div>
          </div>
          <!--<div class="share">
                        <i class="f-icon">&#xf002;</i>
                        已分享
                    </div>-->
        </div>
        <div class="footer">
          <span>限量: <span class="tc-main">{{ goods.limitQuantity||0 }}</span></span>
          <progress-bar
            v-model="goods.sellQuantity"
            :max-value="goods.limitQuantity"
          >
            <div
              class="label"
              slot="label"
            >
              已抢 {{ goods.sellQuantity||0 }} 件
            </div>
          </progress-bar>
        </div>
      </div>
      <tabs class="average ma-t">
        <tab-panel label="请选择款式">
          <list>
            <sku-item
              v-for="(item) in skuList"
              :key="item.id"
              :data="item"
              v-model="item.buyQuantity"
            />
          </list>
        </tab-panel>
        <tab-panel label="商品详情">
          <div
            class="pa-a bc-w"
            style="line-height: 0;"
          >
            <img
              v-for="(item,index) in goods.detailImageList"
              :key="index"
              :src="item"
              width="100%"
            >
          </div>
        </tab-panel>
      </tabs>
      <x-form
        ref="form"
        :rules="rules"
        :model="formData"
      >
        <list class="ma-t ma-b">
          <div>
            <form-item
              prop="allText"
            >
              <x-input
                class="bor-a"
                type="textarea"
                clear
                :auto-size="{minRows:3,maxRow:6}"
                placeholder="粘贴收货地址快速填充地址信息"
                v-model="formData.allText"
                @keydown.enter="onKeyDown"
              />
              <!--<div
                                class="btn btn-main ma-l radius"
                                v-if="formData.allText"
                                @click="matchDeliveryInfo(formData.allText)"
                            >
                                <div class="lh-normal">
                                    智能<br>识别
                                </div>
                            </div>-->
            </form-item>
            <div class="tc-red pa-a fs-small">
              例：姓名,手机号,福建省泉州市丰泽区海洋渔业局3楼,备注
            </div>
          </div>
          <form-item
            label="姓名："
            prop="name"
          >
            <x-input
              type="text"
              clear
              placeholder="请输入"
              v-model="formData.name"
            />
          </form-item>
          <form-item
            label="手机："
            prop="mobile"
          >
            <x-input
              type="text"
              clear
              auto-size
              placeholder="请输入"
              v-model="formData.mobile"
            />
          </form-item>
          <!--<form-item
                        label="区域："
                        prop="regionId"
                    >
                        <region-picker v-model="formData.regionId"></region-picker>
                    </form-item>-->
          <form-item
            label="地址："
            prop="address"
          >
            <x-input
              type="textarea"
              clear
              auto-size
              placeholder="请输入"
              v-model="formData.address"
            />
          </form-item>
          <form-item
            label="备注："
            prop="msg"
          >
            <x-input
              type="textarea"
              clear
              auto-size
              placeholder="请输入"
              v-model="formData.msg"
            />
          </form-item>
        </list>
      </x-form>
    </container>
    <div class="footer">
      <div class="btn btn-g5">
        预览页面时不可操作
      </div>
    </div>
  </Page>
</template>

<script>
import pageMixin from '@/mixins/layout/page';

export default {
  name: 'GoodsDetails',
  mixins: [pageMixin],
  data() {
    return {
      lssj: new Date().getTime() + 10000,
      countdown: {},
      goods: {},
      imgList: [],
      skuList: [],
      formData: {
        name: '',
        mobile: '',
        regionId: '',
        address: '',
        msg: '',
        allText: this.$globalVar.dev
          ? 'name1,13888888888,福建省 泉州市 丰泽区 就在这里,没什么可说的;name2,13888888888,福建省 泉州市 丰泽区 就在这里,没什么可说的;name3,13888888888,福建省 泉州市 丰泽区 就在这里,没什么可说的'
          : '', // name,13888888888,福建省 泉州市 丰泽区 就在这里,没什么可说的
      },
      rules: {
        name: {
          required: true,
          message: '姓名不能为空',
        },
        mobile: [
          {
            required: true,
            message: '手机号不能为空',
          },
          {
            validator: this.$utils.Valid.mobile,
            message: '手机号格式不正确',
          },
        ],
        regionId: {
          required: true,
          message: '区域不能为空',
        },
        address: {
          required: true,
          message: '地址不能为空',
        },
      },
      shareData: {
        link: this.$route.fullPath,
      },
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    $$form() {
      return this.$refs.form;
    },
  },
  beforeRouteEnter(to, from, next) {
    this.$api.Buyer.Gd.Goods.goodsPreviewDetail({ id: to.params.id })
      .then(json => {
        const res = json.data.data;
        next(vm => {
          vm.goods = res;
          vm.skuList = res.skuList;
          vm.imgList = res.imgList.map(item => {
            return {
              img: item,
            };
          });
        });
      })
      .catch(() => {});
  },
  methods: {},
};
</script>

<style lang="scss">
.goods-swiper {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
  > .swiper-container {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  > .sold-out {
    position: absolute;
    z-index: 200;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background: rgba(0, 0, 0, 0.5) url('~@/assets/images/comm/icon01.png')
      center no-repeat;
    background-size: 70%;
    width: 100%;
    height: 100%;
  }
}

.goods-info {
  background-color: #fff;
  > .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;

    > .label,
    > .price-wrap,
    > .countdown-wrap {
      min-height: 1rem;
      padding: 0 $padding;
    }
    > .label {
      flex: 0.54 0.54 1%;
    }
    > .price-wrap {
      flex: 0.54 0.54 1%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      background-color: $color-main;
      .price {
        color: #fff;
        line-height: 1.2;
      }
      > .price {
        font-size: 0.4rem;
      }
      > .old-price {
        color: #fff;
        font-size: 0.22rem;
        line-height: 1.2;
      }
    }
    > .countdown-wrap {
      flex: 0.46 0.46 1%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: $color-yellow;
      > .countdown {
        justify-content: center;
        color: $color-red;
      }
    }
  }
  > .body {
    padding: $padding-big $padding;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > .label-wrap {
      flex: 1 1 auto;
      > .name {
        font-size: 0.32rem;
      }
      > .desc {
        color: $gray6;
        line-height: 1.2;
        margin-top: $margin;
      }
    }
    > .share {
      flex: 0 0 auto;
      padding: $padding;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 0.22rem;
      > .f-icon {
        font-size: 0.6rem;
        line-height: 1;
        margin-bottom: $margin-small;
      }

      &.active {
        color: $color-red;
      }
    }
  }
  > .footer {
    padding: $padding;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}
</style>
