<!-- Created by henian.xu on 2021/10/27. -->

<template>
  <Page>
    <container ref="container">
      <list>
        <list-item
          :extra="detail.feedbackTypeName"
        >
          <div
            slot="label"
            class="label fw-bold"
          >
            工单类型
          </div>
        </list-item>
        <list-item>
          <div
            slot="label"
            style="width: 100%;"
          >
            <div class="label fw-bold">
              工单正文
            </div>
            <div class="ma-t">
              {{ detail.content || '无' }}
            </div>
          </div>
        </list-item>
        <list-item>
          <div
            slot="label"
            style="width: 100%;"
          >
            <div class="label fw-bold">
              工单图片
            </div>
            <div class="ma-t">
              <ImgList
                :column="3"
                :data="detail.feedbackImageList"
              />
            </div>
          </div>
        </list-item>
      </list>
      <list class="ma-t">
        <list-item>
          <div
            slot="label"
            style="width: 100%;"
          >
            <div class="label fw-bold">
              平台处理时间
            </div>
            <div class="ma-t">
              {{ detail.handleTime | moment('YYYY-MM-DD HH:mm:ss') }}
            </div>
          </div>
        </list-item>
        <list-item>
          <div
            slot="label"
            style="width: 100%;"
          >
            <div class="label fw-bold">
              平台处理内容
            </div>
            <div class="ma-t">
              {{ detail.handleRemarks || '无' }}
            </div>
          </div>
        </list-item>
        <list-item>
          <div
            slot="label"
            style="width: 100%;"
          >
            <div class="label fw-bold">
              平台处理图片
            </div>
            <div class="ma-t">
              <ImgList
                :column="3"
                :data="detail.handleAttachmentList"
              />
            </div>
          </div>
        </list-item>
      </list>
    </container>
  </Page>
</template>

<script>
export default {
  name: 'DetailPage',
  data() {
    return {
      feedbackId: this.$route.params.id || '',
      detail: {},
    };
  },
  methods: {
    async getDetail() {
      const { data: res } = await this.$api.Feedback.Mb.Feedback.getDetail({
        id: this.feedbackId,
      });
      console.log(res.data);
      this.detail = res.data;
    },
  },
  created() {
    this.getDetail();
  },
};
</script>

<style lang='scss'>
</style>
