<!-- Created by henian.xu on 2022/7/1. -->

<template>
  <Page>
    <container ref="container">
      <div class="pa-a">
        <GridGroup
          class="bc-w"
          :columns="2"
        >
          <Grid
            v-for="item in meiQiaCustomerService"
            :key="item.id"
            :label="item.label"
            @click="onService(item)"
          >
            <XIcon
              slot="icon"
              :content="item.type===3?'f142':'f128'"
              size="130%"
              :badge="item.type===3?'':unreadMsgCount"
              svg
            />
          </Grid>
        </GridGroup>
      </div>
    </container>
  </Page>
</template>

<script>
import pageMixin from 'mixins/layout/page';
import meiQiaMixin from 'mixins/meiQiaMixin';
import MeiQiaControl from '@/store/meiQia/MeiQiaControl';

export default {
  name: 'CustomerServicePage',
  mixins: [pageMixin, meiQiaMixin],
  data() {
    return {};
  },
  computed: {
    meiQiaCustomerService() {
      return this.$globalVar.meiQiaCustomerService || [];
    },
  },
  methods: {
    onService(item) {
      if (!this.$globalVar.userData.id) {
        this.$router.replace('/buyer/login');
        return;
      }
      if (this.meiQiaAllSet) {
        MeiQiaControl.showPanel(item);
      } else {
        // this.$router.push('/feedback/list');
        window.location.href = this.$globalVar.feedbackUrl;
      }
    },
  },
};
</script>

<style lang='scss'>
</style>
